













































































import {Component, Vue} from "vue-property-decorator";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import {getPageAPIs, Util} from "@/common/util";
import AccountManageAPI from "@/common/api/accountManage";

export const pageApiList = getPageAPIs(AccountManageAPI);
@Component({})
export default class UserList extends Vue {
  private AccountManageAPI: any = new AccountManageAPI();
  private deepLoopUtil: any;
  constructor() {
    super();
    this.deepLoopUtil = new DeepLoop();
  }
  private visible: boolean = false;
  private forms: any = {
    nickname: 0,
    account:'',
    password: "",
    department_id:null
  };
  private operateType:string = 'add'
  private form: any = {};
  private departmentList:any = []

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.AccountManageAPI.members(
        {
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.pageSize = res.data.prepage;
        }
    );
  }

  private getDepartmentList(){
    this.AccountManageAPI.getDepartmentList({
      page: 1,
      limit: 200,
    },(res:any)=>{
      this.departmentList = res.data.list
    })
  }


  private adduser(type: any='add',row:any) {
    console.log('row',row)
    this.restForm();
    this.operateType = type
    this.getDepartmentList()
    this.visible = true;
    if(this.operateType === 'edit'){
      this.forms = row;
    }
  }
  private deluser(id: string): void {
    this.AccountManageAPI.deluser(
        {
          id: id,
        },
        () => {
          Util.showToast("删除成功");
          this.getTable();
        }
    );
  }

  private submit(): void {
    if (this.operateType == "add") {
      this.AccountManageAPI.membersadd(this.forms, () => {
        this.visible = false;
        Util.showToast("新增账号成功");
        this.getTable();
      });
    } else {
      this.AccountManageAPI.edituser(this.forms, () => {
        this.visible = false;
        Util.showToast("修改成功");
        this.search();
      });
    }
  }

  private restForm(): void {
    this.forms = new Util().clearObject(this.forms);
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }


}
