import {BasicsClass} from "@/common/BasicsClass";

export default class AccountManageAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getMembersList', {
            url: '/members/list',
            name: 'list',
            label: '账号列表'
        }],
        ['addMembers', {
            url: '/members/add',
            name: 'add',
            label: '账号添加'
        }],
        ['editMembers', {
            url: '/members/edit',
            name: 'edit',
            label: '账号编辑'
        }],
        ['delMembers', {
            url: '/members/del',
            name: 'del',
            label: '账号删除'
        }],
        ['departmentList', {
            url: '/department/list',
            name: 'departmentList',
            label: '部门列表'
        }],
    ])

    public members(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('getMembersList').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public membersadd(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('addMembers').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public edituser(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('editMembers').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }



    public deluser(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('delMembers').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public getDepartmentList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('departmentList').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
}
